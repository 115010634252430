import React, { FC } from "react";
import { Translate } from "../../components/translations";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";

const TermsOfUse: FC = () => (
  <div className="content-wrapper">
    <Layout>
      <PageHero
        title="TERM_OF_USE_PAGE_TITLE"
        subtitle="TERM_OF_USE_PAGE_SUBTITLE"
        titleSmall
      />
      <Section>
        <div className="col-lg-12">
          <Translate name="TERM_OF_USE_PAGE_CONTENT_HEXOMATIC" />
        </div>
      </Section>
    </Layout>
  </div>
);

export default TermsOfUse;
